<template>
    <div>
        <div class="modal fade" id="mutasiSaldoModal" tabindex="-1" aria-labelledby="mutasiSaldoModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="mutasiSaldoModalLabel" class="font-weight-bold">{{ModalTitle}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CCardBody>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Distributor</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <v-select id="Distributor" class="pb-3" :options="DistributorData" v-model="Distributor" :disabled="DisabledDistributorValue" />
                                    <label id="errorDistributor" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                 <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Tanggal</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <date-picker
                                        id="MutasiSaldoDate"
                                        v-model="MutasiSaldoDate"
                                        format="DD MMM YYYY"
                                        type="date"
                                        placeholder="Select date"
                                        value-type="YYYY-MM-DD"
                                        style="width:100%"
                                    ></date-picker>
                                    <label id="errorMutasiSaldoDate" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow class="pt-3 pb-3">
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Debit</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <input type="number" id="Debit" v-model="Debit" class="font-weight-bold form-control input-number"/>
                                    <label id="errorDebit" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Catatan</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <textarea class="form-control" rows="2" v-model="Note"></textarea>
                                    <label id="errorNote" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <br>
                            <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                            <label style="float: right;">&nbsp;&nbsp;</label>
                            <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                        </CCardBody> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { globalfunc } from '../../../../shared/GlobalFunction.js';
import mutasiSaldoServices from '../Script/MutasiSaldoServices';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'MutasiSaldoForm',
    props: ['reload'],
    components: {
        'date-picker' : datePicker,
    },
    data: function () {
        return {
            ModalTitle : '',
            Id: '',

            DistributorData: [],
            Distributor: '',
            DisabledDistributorValue: '',
            MutasiSaldoDate: '',
            Debit: 0,
            StatusData: [],
            Status: '',
            Note: '',

            Error: 0,
            SaveType: '',
        }
    },
    // mounted(){

    // },
    methods: {
        async addClick(){  
            $(".form-error").css("display", "none");
            $(".save-button").show();
            this.ModalTitle = 'Add Mutasi Saldo';     
            
            this.DistributorData = await mutasiSaldoServices.getDistributorQuery();
            this.Distributor = '';
            var dateNow = moment(new Date()).format("YYYY-MM-DD");
            this.MutasiSaldoDate = dateNow;
            this.Debit = 0;
            this.StatusData = [ {value: 'New', label: 'New'} ];
            this.Status = 'New';
            this.Note = '';
            
            this.SaveType = 'Add';
            this.DisabledDistributorValue = false;
            window.$('#mutasiSaldoModal').modal('show');
        },
        async editClick(data, View){
            $(".form-error").css("display", "none");
            $(".save-button").show();
            
            this.ModalTitle = 'Edit Mutasi Saldo: ' + data.contact_name;

            this.Id = data.debit_mutation_id;

            this.DistributorData = await mutasiSaldoServices.getDistributorQuery();
            this.Distributor = this.DistributorData.find(c => c.value == data.contact_id);
            
            var debitMutDate = moment(data.debit_mutation_date).format("YYYY-MM-DD")
            this.MutasiSaldoDate = debitMutDate;
            
            this.Debit = data.debit;
            this.StatusData = await this.$globalfunc.globalDropdown('ddl_status_saldo_mutation');
            this.Status = data.status;
            this.Note = data.notes;

            this.SaveType = 'Edit';
            
            if(View){
                $(".save-button").hide();
                this.ModalTitle = 'View Mutasi Saldo: ';
            }

            this.DisabledDistributorValue = true;
            window.$('#mutasiSaldoModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            if(this.Distributor == '' || this.Distributor == null){
                this.errorShow('errorDistributor');
            }

            if(this.MutasiSaldoDate == '' || this.MutasiSaldoDate == null){
                this.errorShow('errorMutasiSaldoDate');
            }

            if(this.Debit.toString() == '' || this.Debit == null){
                this.errorShow('errorDebit');
            }
            else if(this.Debit < 0 || this.Debit == null){
                this.errorShow('errorDebit', response.fieldRequiredNotMinus);
            }
        },
        async saveClick(){
            this.inputValidation();
            
            if(this.Error == 0){
                //Add
                if(this.SaveType == 'Add'){
                    const data = {
                        debit_mutation_date: this.MutasiSaldoDate,
                        status: this.Status,
                        contact_id: this.Distributor.value,
                        debit: parseFloat(this.Debit).toFixed(2),
                        notes: this.Note,
                    };

                    const variables = {
                        data : data
                    }
                    
                    mutasiSaldoServices.addQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successSave, "success");
                        window.$('#mutasiSaldoModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error);
                        this.$swal("Error", errorMessage, "error");
                    })
                }
                //Edit
                else if(this.SaveType == 'Edit'){
                    const data = {
                        debit_mutation_date: this.MutasiSaldoDate,
                        status: this.Status,
                        contact_id: this.Distributor.value,
                        debit: parseFloat(this.Debit).toFixed(2),
                        notes: this.Note,
                    };

                    const variables = {
                        id : this.Id,
                        data : data
                    }
                    
                    mutasiSaldoServices.editQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successUpdate, "success");
                        window.$('#mutasiSaldoModal').modal('hide');
                        this.$props.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = globalfunc.ErrorExtractor(error);
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode, message){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = message == undefined ? response.fieldRequired : message;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    }
}
</script>

<style scoped>
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
.label-file {
  width : 100%;
  overflow:hidden;
  display:inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>