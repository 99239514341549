import gql from 'graphql-tag';
import { globalfunc } from '../../../../shared/GlobalFunction.js';

class MutasiSaldoService {
    readMutationSaldoQuery(){
        var query = `query ($paging:ServerPaging, $status:String) {
            GetTransactionDebitMutation (Paging:$paging, Status:$status) {
                debit_mutation {
                    created_at
                    last_update
                    debit_mutation_id
                    debit_mutation_date
                    status
                    contact_id
                    contact_name
                    debit
                    notes
                    created_by
                }
                total
            }
        }`;
        return query;
    }

    async getMutationSaldoQueryById(id){
        const variables = {
            id : id
        }

        var query = gql`
            query ($id:Int!) {
                GetTransactionDebitMutation (DebitMutationId:$id) {
                    debit_mutation {
                        created_at
                        last_update
                        debit_mutation_id
                        debit_mutation_date
                        status
                        contact_id
                        contact_name
                        debit
                        notes
                        created_by
                    }
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);

        return result.data.GetTransactionDebitMutation.debit_mutation[0];
    }

    async getDistributorQuery(){
        var query = gql`query{
            GetContact(ContactType:"Distributor"){
                contact_id
                contact_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetContact != null){
            for (let i = 0; i < result.data.GetContact.length; i++) {
                var str = { value:result.data.GetContact[i].contact_id, 
                            label:result.data.GetContact[i].contact_name}
                arrayData.push(str);
            }
        }
        return arrayData;
    }  

    async addQuery(variables){
        var query = gql`mutation($data:NewTransactionDebitMutation!){
            CreateTransactionDebitMutation(New:$data)
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async editQuery(variables){
        var query = gql`mutation($id:Int!,$data:NewTransactionDebitMutation!){
            UpdateTransactionDebitMutation(DebitMutationID:$id,New:$data)
        }`;
          
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async deleteQuery(variables){
        const query = gql`mutation($id:Int!){
            DeleteTransactionDebitMutation(DebitMutationID:$id)
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables);
    }
}

export default new MutasiSaldoService();